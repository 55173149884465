<script setup lang="ts">
   import { ClientMenuItems } from '@/const/ClientMenuItems';
   import { TherapistMenuItems } from '@/const/TherapistMenuItems';

   import {
      ArrowLeftStartOnRectangleIcon,
      Bars3Icon,
      ChevronDownIcon,
      PencilSquareIcon,
      StarIcon,
      UserIcon,
   } from '@heroicons/vue/24/outline';
   import type { ClientProfile } from '~/types/clientInformation';
   const { isClient, clientProfile, clientImage } =
      storeToRefs(useClientInfo());
   const { therapistInfo, therapistImage } = storeToRefs(
      useTherapistInfoStore()
   );
   const openSideBar = defineModel<boolean>('openSideBar', {
      required: true,
   });
   const route = useRoute();
   const { t } = useI18n();

   const userInformation = computed(() => {
      return isClient.value ? clientProfile.value : therapistInfo.value;
   });
   const userImage = computed(() => {
      return isClient.value ? clientImage.value : therapistImage.value;
   });

   const userName = computed(() => {
      return userInformation.value?.first_name ?
            (userInformation.value?.first_name || '') +
               ' ' +
               (userInformation.value?.last_name || '')
         :  (userInformation.value as ClientProfile)?.username || '';
   });
   const items = computed(() =>
      isClient.value ? ClientMenuItems : TherapistMenuItems
   );
   const isNamingMeta = computed(() => route.meta?.title ?? '');

   const currentPage = computed(() =>
      isNamingMeta.value ?
         {
            name: isNamingMeta.value,
         }
      :  items.value.find((item) =>
            (route.name as string)?.startsWith(item.to.name)
         )
   );

   const profileMenuOptions = computed(() => [
      {
         name: t('header.myAccount'),
         to: {
            name:
               isClient.value ?
                  'client-account-overview'
               :  'therapist-account-profile',
         },
         id: 'overview',
         prependIcon: UserIcon,
      },
      isClient.value ?
         {
            name: t('header.myTherapist'),
            to: { name: 'client-therapist-profile' },
            id: 'myTherapist',
            prependIcon: PencilSquareIcon,
         }
      :  {
            name: t('header.myEarnings'),
            to: { name: 'therapist-my-earnings' },
            id: 'myEarnings',
            prependIcon: StarIcon,
         },
      {
         name: t('shared.logout'),
         id: 'logout',
         prependIcon: ArrowLeftStartOnRectangleIcon,
         fn: openLogOut,
         class: 'text-red-500',
      },
   ]);
   const logOutDialog = ref(false);

   const openLogOut = () => {
      logOutDialog.value = true;
   };
</script>

<template>
   <div
      class="h-20 px-4 lg:px-5 relative z-40 flex items-center justify-between border-b border-gray-25 bg-white"
   >
      <!-- mobile menu -->
      <Bars3Icon
         class="w-6 h-6 text-black cursor-pointer lg:hidden"
         @click.prevent="openSideBar = true"
      />

      <div class="flex items-center gap-2">
         <component
            v-if="currentPage"
            :is="currentPage.icon"
            class="hidden md:flex xl:w-6 xl:h-6 w-5 h-5 text-primary-500"
         />
         <span v-if="currentPage" class="font-medium lg:text-md xl:text-xl">
            {{
               route?.meta?.noTranslation ?
                  currentPage.name
               :  t(`sideMenu.${currentPage.name}`)
            }}
         </span>
         <!-- <LayoutCurrencySelect /> -->
         <LayoutTimeZoneSelect :class="currentPage ? 'lg:ms-3' : ''" />
      </div>
      <div class="flex items-center gap-x-4">
         <LayoutLanguageSelect v-if="isClient" class="hidden lg:block" />

         <!-- Separator -->
         <div class="hidden lg:block lg:h-7 lg:w-px lg:bg-gray-25" />

         <!-- Profile Menu -->
         <BaseMenuList
            :options="profileMenuOptions"
            mobile-Menu
            width="w-44 lg:w-64"
         >
            <div class="flex items-center gap-3">
               <div class="text-end hidden lg:block">
                  <p class="text-sm font-medium text-black" v-if="userName">
                     {{ userName }}
                  </p>
                  <p
                     :title="userInformation?.email"
                     class="text-xs mt-1 text-gray-200 truncate overflow-hidden max-w-36"
                  >
                     {{ userInformation?.email }}
                  </p>
               </div>
               <BaseAvatar
                  :image-id="userInformation?.image"
                  id="headerUserImage"
                  :key="`${userImage}`"
                  :role="isClient ? 'client' : 'therapist'"
                  classes="w-10 h-10 object-cover rounded-full"
                  :alt="`${userName} Profile Picture`"
               />

               <ChevronDownIcon class="h-4 w-4 text-gray-80" />
            </div>
         </BaseMenuList>
      </div>
   </div>

   <BaseDialog v-model:open-dialog="logOutDialog" size="sm">
      <template #header></template>
      <LayoutLogOutDialog @close="logOutDialog = false" />
   </BaseDialog>
</template>
